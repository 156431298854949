.bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(99, 49, 180, 0.2) 0%, rgba(99, 49, 180, 0) 100%), #070518;
    z-index: -1;
}

.header {
    @media (max-width: 920px) {
        padding-top: 12px;
    }
}

.top_image {
    border-radius: 50%;
}

.plate {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;

    // .plate__bottom
    &__bottom {
        width: calc(100% - 48px) !important;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 0;
    }
}

.tg_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%), #138ac5;
    line-height: 24px;
    vertical-align: text-bottom;
}
